import * as Sentry from "@sentry/sveltekit";

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: "https://9c5eec10d45e43bd9994f1df7d3a9588@o4504989492903936.ingest.sentry.io/4504989495853056",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [new Sentry.Replay()]
})

export const handleError = Sentry.handleErrorWithSentry();